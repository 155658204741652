// Import our custom variables
@import 'variables';

// ----- Bootstrap Imports --------
// Configuration
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/accordion';
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/carousel';

// Helpers
@import '../../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../../node_modules/bootstrap/scss/utilities/api';

// ------ Font awesome Imports ------
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";

/* -- defaults -- */
body {
    background-color: $dark-blue;
    color: hsl(0, 0%, 100%);
}

a {
    text-decoration: none;
}

img {
	width: auto;
}

.color-gradient {
    /* Fallback: Set a background color. */
    background-color: $dark-blue;

    /* Create the gradient. */
    background-image: linear-gradient($purple, $light-blue);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.color-green {
    color: $light-green;
}

.color-dark-blue {
    color: $dark-blue;
}

.bg-purple {
    background-color: $purple;
}

.bg-green {
    background-color: $light-green;
    color: $dark-blue;

    .btn-primary {
        border-color: $dark-blue;
    }
}

.light-opacity {
    opacity: 0.7;
}

/* override bootstrap */

.btn-primary {
    padding: 8px 20px;
    border-radius: 9px;
    background-color: $light-green;
    color: $dark-blue;
    border-color: transparent;
    white-space: nowrap;
    transition: all 0.3s;

    &:hover,
    &:focus {
        color: $light-green;
        background-color: $dark-blue;
        border-color: $light-green;
        box-shadow: unset;
    }
}

.btn-secondary {
    padding: 8px 20px;
    border-radius: 9px;
    color: $light-green !important;
    background-color: $dark-blue !important;
    border-color: $light-green;
    white-space: nowrap;
    transition: all 0.3s;
    text-decoration: none !important;

    &:hover,
    &:focus {
        background-color: $light-green !important;
        color: $dark-blue !important;
        border-color: transparent;
        box-shadow: unset;
        text-decoration: none !important;
    }
}

.banner {
    background: $purple-blue-gradient-horizontal;

    a {
        color: hsl(0, 0%, 100%);
        text-decoration: underline;
    }

    p {
        margin: auto;
    }
}

.separator {
    hr {
        color: $purple;
        height: 2px;
        opacity: 1;
    }
}

/* -- navbar styling -- */

nav.navbar {
    padding-top: 3rem !important;

    .navbar-nav {
        margin-left: auto;
    }

    .navbar-brand {
        img {
            width: calc(8rem + 1vw);
        }
    }

    .navbar-toggler:active {
        background-color: inherit;
    }

    .nav-link {
        color: hsl(0, 0%, 100%) !important;
        padding-right: calc(2rem + 1vw) !important;

        &:hover {
            text-decoration: none;
            background-color: inherit;
            color: $purple !important;
        }
    }

    @media (max-width: 992px) {
        .nav-try-now {
            display: none;
        }
    }
}

/* navbar animations */
@media (min-width: 992px) {
    .dropdown-menu {
        animation-duration: 0.3s;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .dropdown-toggle::after {
        content: none;
    }

    .nav-item:hover .dropdown-menu {
        display: block;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@keyframes slideIn {
    0% {
        transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.dropdown-menu {
    // So that the dropdown doesn't close when moving between button and dropdown
    margin-top: 0;

    // Animation for dropdown menu.
    animation-name: slideIn;
    animation-name: slideIn;
}

// style for home.njk
.home_page {
    img {
        // Fixes img stretching to whole flex container
        align-self: center;
    }

    .hero-try-now:hover {
        background-color: hsl(155, 86%, 72%);
        color: $dark-blue;
        border-color: transparent;
    }

    .btn.watch-now svg {
        position: relative;
        top: -2px;
    }

    @media (max-width: 992px) {
        .hero-try-now,
        .watch-now {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .introduction-video-section {
        background: $purple-blue-gradient;
        text-align: center;

        .introduction-video {
            width: 60%;
            margin: auto;
            padding: 3%;
        }
    }

    .no-more-buried-messages {
        background-image: url('../../images/Homepage_Threading2.svg');
        background-repeat: no-repeat;
        background-position: 0% 26%;
    }

    .apps-section {
        background-color: $purple;
        text-align: center;
    }

    @media (min-width: 992px) {
        .integrations-section {
            background-image: url('../../images/Homepage_Integration.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .row {
                height: 40vw;
            }
        }
    }

    .integrations-section {
        .col-lg-6 {
            align-self: center;
        }
    }

    .testimonials {
        background-color: $purple;

        img {
            height: $home-testimonial-avatar-height;
            max-width: $home-testimonial-avatar-height;
        }

        .carousel-control {
            span {
                position: absolute;
                top: calc(#{$home-testimonial-avatar-height} / 2);
                bottom: unset;
            }
        }
    }

    .trusted-by {
        h1 {
            text-align: center;
            color: $purple;
        }

        .company-box {
            display: block;
            padding: 20px;

            .company {
                display: inline-block;
                width: 100%;
                height: 60px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin: 10px 0 !important;
            }
        }
    }

    .second-apps-section {
        background-color: transparent;

        h1 {
            color: $purple;
        }
    }
}

/* -- company logos -- */
.company-box .company.akamai {
    background-image: url(../../images/logos/akamai.png);
    background-color: transparent;
}

.company-box .company.doctorondemand {
    background-image: url(../../images/logos/doctorondemand.png);
    background-color: transparent;
}

.company-box .company.layershift {
    background-image: url(../../images/logos/layershift.png);
    background-color: transparent;
}

.company-box .company.recurse {
    background-image: url(../../images/logos/recurse.png);
    background-color: transparent;
}

.company-box .company.cmt {
    background-image: url(../../images/logos/cambridge-mobile-telematics.png);
    background-color: transparent;
}

.company-box .company.levelup {
    background-image: url(../../images/logos/levelup.png);
    background-color: transparent;
}

.company-box .company.mariadb {
    background-image: url(../../images/logos/mariadb.png);
    background-color: transparent;
}

.company-box .company.wikimedia-outreach {
    background-image: url(../../images/logos/wikimedia-outreach.png);
    background-color: transparent;
}

.landing-footer {
    background-color: $dark-blue;

    ul,
    li,
    h3 {
        color: hsl(0, 0%, 100%);
    }

    a {
        color: hsl(0, 0%, 100%);

        &:hover {
            text-decoration: none;
            color: $purple;
        }
    }
}

.features_page {
    img {
        max-height: 450px;
    }

    .card {
        background-color: transparent;
        cursor: pointer;
        transition: all 150ms ease;
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
        border-radius: 5px;

        .card-img-top {
            svg {
                color: $dark-blue;
                width: 2em;
                height: 2em;
                margin: 1em;
            }
        }

        .card-title {
            color: $dark-blue;
        }

        &:hover {
            border-color: $dark-blue;
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
            transform: translateY(-3px);
        }

        &.green {
            .card-title {
                color: $light-green;
            }

            svg {
                color: $light-green;
            }

            &:hover {
                border-color: $light-green;
            }
        }
    }

    .apps-section {
        h1 {
            color: $purple;
        }
    }
}

.community_page {
    .ways_to_contribute {
        background-color: $purple;

        .card {
            color: hsl(0, 0%, 100%);
            background-color: $purple;
            border: 0;

            .img-col {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                max-height: 7em;
                margin: auto;
                display: block;
            }
        }
    }
}

.apps_page {
	.app-badge {
		text-decoration: none !important;

		img {
			width: 120px;
			height: 40px;
		}
	}

    .underline-link,
    #third-party-apps {
        a {
            color: white;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: white;
                text-decoration: underline;
            }
        }
    }
    .apps {
        .icons {
            font-size: 3rem;
            color: hsla(210, 75%, 17%, 68%);
            padding: 30px;

            &:hover,
            &:focus {
                color: $light-green;
                opacity: 80%;
            }
        }

        @media (min-width: 768px) {
            .icons::after {
                content: attr(data-label);
                display: block;
                margin-top: 10px;
                color: white;
                font-size: 0.8rem;
                font-family: sans-serif;
                font-weight: 600;
            }
        }
    }
    .download-buttons-section {
        margin-bottom: 30px;
    }
}

.download-from-google-play-store,
.download-from-app-store,
#download-android-apk {
    display: none !important;
}

.android,
.ios {
    #download-android-apk {
        font-size: 0.7rem;
        margin-left: 20px;
        white-space: nowrap;
    }
    button.btn-secondary {
        display: none !important;
    }

    .apps_image_container {
        text-align: center;
    }

    .homepage_hero_img {
        width: 250px;
        margin: auto;
    }
}

.android {
    .download-from-google-play-store,
    #download-android-apk {
        display: inline !important;
    }
}

.ios {
    .download-from-app-store {
        display: inline !important;
    }
}

.team-page {
    .team-profiles {
        border-top: 1px solid #ededed;
        padding-top: 10px;
    }
    .profile-picture {
        max-width: 250px;

        img {
            border-radius: 5px;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 768px) {
        .profile-name,
        .profile-role {
            text-align: center;
        }
    }

    #contribution-tab {
        font-size: 0.9rem;

        .nav-link {
            padding: 4px 11px;
            color: black;
            opacity: 0.8;
        }

        .nav-link.active {
            color: #0d6efd;
            opacity: 1;
        }
    }

    .tab-content {
        margin-top: 10px;
    }

    .card {
        transition: all 0.5s;
        img {
            width: 60px;
            border-radius: 2px;
        }
        .avatar {
            margin: auto;
            text-align: center;
        }
        .card-body {
            padding: 0.5rem;
        }

        &:hover,
        &:focus {
            border-color: hsla(0, 0%, 0%, 0.5);
        }
    }

    .profile-link {
        text-decoration: none;
        color: black;
        &:hover {
            text-decoration: none;
            color: black;
        }
    }
}

.pricing-page {
    .pricing-header {
        h1 {
            font-weight: 1000;
        }

        h6 {
            color: $light-green;
        }
    }

    @media (max-width: 1400px) {
        .pricing-header {
            text-align: center;
        }
    }

    .plan-box {
        background-color: $purple;
        min-height: 100%;
        border-radius: 6px;
        padding: 10px 16px 10px;
        display: flex;
        flex-direction: column;

        .plan-title {
            display: flex;
            flex-direction: column;
            height: 120px;

            .price-detail {
                font-size: 14px;
            }

            .title {
                font-weight: 500;
                color: $dark-blue;
                font-size: 45px;
            }

            .price-sign {
                font-size: 20px;
                position: relative;
                bottom: 17px;
            }
        }
    }

    .desktop-top-features {
        margin-top: auto;
        display: flex;
        flex-direction: column;

        .feature {
            padding: 15px 5px 25px;
            border-bottom: 1px solid #ccc;
            height: 80px;
            position: relative;

            p {
                position: absolute;
                bottom: 0;
            }
        }
    }

    .asterisk-detail {
        font-size: 12px;
        font-style: italic;
        height: 40px;
    }
}

.feature-comparison-table {
    p {
        margin-bottom: 0;
    }

    .table {
        color: white !important;

        * {
            border-color: $purple;
            border-bottom-color: $purple !important;
        }

        .row-title {
            background-color: $purple;
            color: $dark-blue;
            font-size: 1.25rem;
            font-weight: 600;
        }

        thead tr,
        tbody .feature-description {
            text-align: center;
        }
    }
}

.faq {
    background-color: $purple;

    .accordion-body {
        a {
            color: white;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: white;
                text-decoration: underline;
            }
        }
    }

    * {
        border: 0 !important;
    }

    .accordion-item {
        border-bottom: 1px solid white !important;
    }

    .accordion-button:not(.collapsed) {
        background-color: $purple;
    }

    .accordion-button {
        font-size: 1.04rem;
        font-weight: 700;
        color: white !important;

        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
        }
    }

    * {
        border-color: white;
        border-bottom-color: white !important;
    }
}

.photo-description {
    position: relative;
    top: -40px;
    text-align: center;
    font-size: 0.8rem;
    font-color: hsla(0, 0%, 0%, 0.1);
}

.history {
    h1 {
        border-bottom: 1px solid hsl(0, 0%, 93%);
        padding-bottom: 10px;
        margin-bottom: 15px;
    }

	.sponsor-picture {
		img {
			width: 150px;
		}
	}
}

.markdown-content {
    .markdown-image-center {
        width: 500px;
        margin: auto;
        display: block;
    }

    blockquote {
        border-left: 3px solid $purple;
        padding: 20px;
        padding-bottom: 5px;
        color: hsla(0, 0%, 0%, 0.7);

        p {
            margin-bottom: 5px;
        }
    }

    .testimonials {
        img {
            height: $home-testimonial-avatar-height;
        }
        .carousel-control {
            span {
                position: absolute;
                top: calc(200px / 2);
                bottom: unset;
                background-color: $light-blue;
                border-radius: 30px;
                border: 4px solid $light-blue;
            }
        }
    }
}

.sub-title {
    opacity: 0.7;
}
